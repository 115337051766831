import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { Message } from 'element-ui';

import store from "../store/index";
import { selectLeftMenu } from "../api/admin/login";


Vue.use(VueRouter)

const router = new VueRouter({
  base:process.env.BASE_URL,
  mode: 'history',
  routes
})

router.beforeEach(async (to,from,next)=>{
  // to 访问的路由信息对象
  //    path、params、query、meta
  if(to.meta.isPublic){
    return next()
  }
  
  let token = localStorage.getItem("token")
  if(token==null){
    Message.error("请先登录")
    if(from.path!=="/"){
      return next("/")
    }
  }else{
    // 判断当前登录用户是否具有权限访问对应的路由地址
    // 先判断仓库中是否已经记录了当前登录用户的 权限列表
    if(store.state.userRight.length===0){
      // 基于当前登录的授权用户 请求当前用户的 所有权限列表
      let leftMenu = await selectLeftMenu();
      // 将用户的权限存储到vuex中 => 1. 用于路由守卫的权限判断，用于首页的左侧菜单渲染
      // !!! 退出时必须清空 vuex的存储权限数据
      store.commit("setUserRight",leftMenu);
    }

    // 判断 访问的路由路径 ，是否是当前用户可以授权访问的路由路径
    // console.log(store.state.userRight);
    // console.log(to.path);
    let flag = to.path === "/home";
    let authFlag = store.state.userRight.some( item=>item.right_path===to.path );
    if(flag||authFlag){
      // 如果存在权限，可以直接访问 => vue2 的路由会因为多次重定向报错，但不影响功能 
      //                        => 功能移动到 HomeView 组件的 mounted 函数中
      // if(flag){
      //   // 判断访问的是 /home , 直接切换到当前用户的权限首页
      //   return next( store.state.userRight[0].right_path )
      // }else{
      //   // 判断访问的不是 /home , 直接放行
      //  return next()
      // }
      return next()
    }else{
      // 没有权限直接返回当前用户的首页
      return next( store.state.userRight[0].right_path )
    }
  }
} )

export default router
