
// 导出的对象 和 组件配置项使用统一规则
// 在 main.js 中使用 Vue.mixin() 进行加载
export default {
    computed: {
        // 该方法适合定义在  vue 的 Vue.mixin 配置混合中
        //      将项目中组件都有的功能不变的 方法，属性 ，统一定义，vue自动为所有组件增加该配置
        getMixinAuth() {
            // 当前的路径 [能够执行该计算属性，意味正在访问当前页面，该页面是路由页面，激活路由信息对象就是当前组件的信息对象]
            // console.log(this.$route.path);
            let path = this.$route.path;

            // 从vuex中加载出当前登录用户的所有权限，包括页面内部的按钮权限的 和值
            // console.log(this.$store.state.userRight);
            let rights = this.$store.state.userRight;

            let authCount = 1;
            for (let i = 0; i < rights.length; i++) {
                if (rights[i].right_path === path) {
                    authCount = rights[i].right_function;
                    break;
                }
            }

            return authCount;
        }
    }
}