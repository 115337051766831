export default [
    // 登录页面
    {
        path:"/",
        component:()=>import("@/views/LoginView.vue"),
        meta:{
            isPublic:true
        }
    },
    // 登录后的首页
    {
        path:"/home",
        component:()=>import("@/views/HomeView.vue"),
        children:[
            // {
            //     // 子路由的默认地址
            //     path:"",
            // },
            {
                path:"admin/welcome",
                // 对打包文件进行分组构成
                component:()=>import(/* webpackChunkName: "group-a" */"@/views/admin/AdminWelcome.vue")
            },
            {
                path:"admin/depart",
                component:()=>import(/* webpackChunkName: "group-a" */"@/views/admin/DepartmentView.vue")
            },
            {
                path:"admin/major",
                component:()=>import(/* webpackChunkName: "group-a" */"@/views/admin/MajorView.vue")
            },
            {
                path:"admin/class",
                component:()=>import(/* webpackChunkName: "group-a" */"@/views/admin/ClassView.vue")
            },
            {
                path:"admin/teacher",
                component:()=>import(/* webpackChunkName: "group-a" */"@/views/admin/TeacherView.vue")
            },
            {
                path:"admin/student",
                component:()=>import(/* webpackChunkName: "group-b" */"@/views/admin/StudentView.vue")
            },
            {
                path:"admin/course",
                component:()=>import(/* webpackChunkName: "group-b" */"@/views/admin/CourseView.vue")
            },
            {
                path:"admin/choose",
                component:()=>import(/* webpackChunkName: "group-b" */"@/views/admin/ChooseView.vue")
            },
            {
                path:"admin/manager",
                component:()=>import(/* webpackChunkName: "group-b" */"@/views/admin/ManagerView.vue")
            },
            // =============
            {
                path:"teacher/welcome",
                component:()=>import(/* webpackChunkName: "group-c" */"@/views/teacher/TeacherWelcome.vue")
            },
            {
                path:"teacher/course",
                component:()=>import(/* webpackChunkName: "group-c" */"@/views/teacher/CourseView.vue")
            },
            {
                path:"teacher/table",
                component:()=>import(/* webpackChunkName: "group-c" */"@/views/teacher/CourseTable.vue")
            },
            {
                path:"teacher/source",
                component:()=>import(/* webpackChunkName: "group-c" */"@/views/teacher/SourceView.vue")
            },
            // ==================
            {
                path:"student/welcome",
                component:()=>import(/* webpackChunkName: "group-c" */"@/views/student/StudentWelcome.vue")
            },
            {
                path:"root/right",
                component:()=>import(/* webpackChunkName: "group-d" */"@/views/root/RightsView.vue")
            },
            {
                path:"root/role",
                component:()=>import(/* webpackChunkName: "group-d" */"@/views/root/RoleView.vue")
            }
        ]
    },
    {
        path:"/*",
        redirect:"/",
        meta:{
            isPublic:true
        }
    }
]